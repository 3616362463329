import Video from '../../assets/video/video-desktop.mp4';
import VideoMobile from '../../assets/video/video-mobile.mp4';
import './styles.css';

const Splash = ({openMenu}) => {
    const isMobile = window.innerWidth <= 768

    return (
        <div className='container-fluid the-splash px-0'>
            <div className="the_splash_container">
                <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                    <source src={isMobile ? VideoMobile : Video} type="video/mp4"/>
                </video>
            </div>
        </div>
    );
};

export default Splash;