import './styles.css';

const CastModal = ({data, setData, modalOpened, setModalOpened}) => {
  return (
    <>
      {
        modalOpened && (
          <div className="sidenav-overlay" onClick={() => setModalOpened(false)}></div>
        )
      }
      <div className={`sidenav ${modalOpened ? 'showingg' : ''}`}>
        <span className="closebtn" onClick={() => setModalOpened(false)}>&times;</span>
        <div className="inner">
            <div className="image-wrapper-cast">
              <img src={data.image} alt={data.title} />
            </div>
            <div className="text-center mt-4 px-4">
              <h2>
                {
                  data.title
                }
              </h2>
              <p className='text-light'>
                {
                  data.text
                }
              </p>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center social-icons-cast">
                    {
                      data.networks.map((item) => (
                        <span className="fa-stack">
                            <a href={item[1]} target='_blank' rel='noreferrer'>
                                <i className={`fab fa-${item[0]} fa-stack-1x`}></i>
                            </a>
                        </span>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default CastModal;