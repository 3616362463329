import './styles.css';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import Parceiro1 from '../../assets/images/partners/parceiro-1.png';
import Parceiro2 from '../../assets/images/partners/parceiro-2.png';
import Parceiro3 from '../../assets/images/partners/parceiro-3.png';
import Parceiro4 from '../../assets/images/partners/parceiro-4.png';
import Parceiro5 from '../../assets/images/partners/parceiro-5.png';
import Parceiro6 from '../../assets/images/partners/parceiro-6.png';
import Parceiro7 from '../../assets/images/partners/parceiro-7.png';
import Parceiro8 from '../../assets/images/partners/parceiro-8.png';
import Parceiro9 from '../../assets/images/partners/parceiro-9.png';
import Parceiro10 from '../../assets/images/partners/parceiro-10.png';
import Parceiro11 from '../../assets/images/partners/parceiro-11.png';
import Parceiro12 from '../../assets/images/partners/parceiro-12.png';
import Parceiro13 from '../../assets/images/partners/parceiro-13.png';
import Parceiro14 from '../../assets/images/partners/parceiro-14.png';
import Parceiro15 from '../../assets/images/partners/parceiro-15.png';
const animation = { duration: 20000, easing: (t) => t }



const PARTNERS = [
  Parceiro1,
  Parceiro2,
  Parceiro3,
  Parceiro4,
  Parceiro5,
  Parceiro6,
  Parceiro7,
  Parceiro1,
  Parceiro2,
  Parceiro3,
  Parceiro4,
  Parceiro5,
  Parceiro6,
  Parceiro7,
]

const AUX_PARTNERS = [
  Parceiro8,
  Parceiro9,
  Parceiro10,
  Parceiro11,
  Parceiro12,
  Parceiro13,
  Parceiro14,
  Parceiro15,
]

const getSlidesByBreakpoint = () => {
  if (window.innerWidth < 375) {
    return 1
  } else if (window.innerWidth < 768) {
    return 2
  } else {
    return 6
  }
}

const Partners = () =>  {
  const [sliderRef] = useKeenSlider({
    loop: true,
    drag: false,
    slides: {
      perView: getSlidesByBreakpoint(),
      spacing: 40,
    },
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })

  const [auxSliderRef] = useKeenSlider({
    loop: true,
    drag: false,
    rtl: true,
    slides: {
      perView: getSlidesByBreakpoint(),
      spacing: 40,
    },
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  });
  
  return (
    <section className="partners-container" >
      <div id="parceiros"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="container">
            <h2 className="partners-heading" anim-viewport="right">
              PARCEIROS
            </h2>

            <div className="row slides-partners">
              <div className="slider-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  {
                    PARTNERS.map((partner, index) => (
                      <div key={index} className="keen-slider__slide">
                        <img src={partner} alt="Parceiro" className='img-fluid'/>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="slider-wrapper">
                <div ref={auxSliderRef} className="keen-slider">
                  {
                    AUX_PARTNERS.map((partner, index) => (
                      <div key={index} className="keen-slider__slide">
                        <img src={partner} alt="Parceiro" className='img-fluid'/>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default Partners;