import './styles.css';

const WhoWeAre = () => (
    <section className="whoweare-container">
        <div id="sobre"></div>
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2 className="text-center whoweare-heading mb-3" anim-viewport="up">Somos a Flocks, sejam bem vindos!</h2>
                    <p className="text-center whoweare-text" anim-viewport="up">
                        Especialistas em criatividade, marketing digital e focados em resultados, pensamos <em>com carinho</em>, produzimos <em>com qualidade</em> e entregamos <em>com agilidade</em> soluções sob medida para sua marca, produto ou serviço.
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default WhoWeAre;