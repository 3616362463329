import './styles.css';
import React from 'react';
import FlocksWhite from '../../assets/images/flocks-white.png';

const Navbar = () => {
  const [transparent, setTransparent] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setTransparent(window.scrollY < 50);
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  const inlineStyles = {
    backgroundColor: transparent ? "transparent" : "#5A3495",
    opacity: 1,
    paddingBlock: transparent ? "1.5rem" : ".5rem",
    transition: "background-color 0.2s ease-in-out, padding-block 0.2s ease-in-out, opacity 0.2s ease-in-out",
  };

  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  return (
    <section className={`navbar-container ${transparent ? 'glassy' : ''}`} style={inlineStyles}>
       <img src={FlocksWhite} alt="" className="brand" />
       
        <span className="nav-item linkosos d-none d-lg-block">
          <a href="#sobre">sobre</a>
          <a href="#memeawards">memeawards</a>
          <a href="#solucoes">soluções</a>
          <a href="#criadores">criadores</a>
          <a href="#trabalhos">trabalhos</a>
          <a href="#parceiros">parceiros</a>
          <a href="#contato">contato</a>
        </span>

        <div className="wrapper-menu  d-block d-lg-none">
          <div className={`abs-menu ${menuVisible ? 'showing-menu' : ''}`}>
            <a href="#sobre">sobre</a>
            <a href="#memeawards">memeawards</a>
            <a href="#solucoes">soluções</a>
            <a href="#criadores">criadores</a>
            <a href="#trabalhos">trabalhos</a>
            <a href="#parceiros">parceiros</a>
            <a href="#contato">contato</a>
          </div>
        </div>
        <span className="nav-item d-block d-lg-none">
          <button className="btn btn-outline-light burger" onClick={toggleMenu}>
          ☰
          </button>
        </span>
      </section>
);
}

export default Navbar;