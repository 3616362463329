import Alcance from '../../assets/images/data/alcance.png';
import Seguidores from '../../assets/images/data/seguidores.png';
import Views from '../../assets/images/data/views.png';
import Engajamento from '../../assets/images/data/engajamento.png';
import Mapa from '../../assets/images/data/mapa.png';
import { useKeenSlider } from 'keen-slider/react';
import { useState } from 'react';
import './styles.css';

const Data = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <section className="data-container mt-4">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="navigation-wrapper">
                    <div ref={sliderRef} className="keen-slider data-ks">
                      <div className="keen-slider__slide slide-data">
                        <img src={Alcance} alt="Alcance" className="img-fluid job-card" />
                      </div>

                      <div className="keen-slider__slide slide-data">
                        <img src={Seguidores} alt="Seguidores" className="img-fluid" />
                      </div>

                      <div className="keen-slider__slide slide-data">
                        <img src={Views} alt="Views" className="img-fluid" />
                      </div>

                      <div className="keen-slider__slide slide-data">
                        <img src={Engajamento} alt="Engajamento" className="img-fluid" />
                      </div>

                      <div className="keen-slider__slide slide-data">
                        <img src={Mapa} alt="Mapa" className="img-fluid" />
                      </div>
                    </div>
                    {loaded && instanceRef.current && (
                      <>
                        <Arrow
                          left
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                          }
                          disabled={currentSlide === 0}
                          className="d-none d-lg-block"
                        />

                        <Arrow
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                          }
                          disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                          }
                        />
                      </>
                    )}
                    {loaded && instanceRef.current && (
                      <div className="dots">
                        {[
                          ...Array(instanceRef.current.track.details.slides.length).keys(),
                        ].map((idx) => {
                          return (
                            <button
                              key={idx}
                              onClick={() => {
                                instanceRef.current?.moveToIdx(idx)
                              }}
                              className={"dot" + (currentSlide === idx ? " active" : "")}
                            ></button>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
)
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <div className="arrow-wrap d-none d-lg-block">
        <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    </div>
  )
}

export default Data;