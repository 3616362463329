import './styles.css';
import proj001 from '../../assets/images/projectimgs/001_proj.jpg';
import proj002 from '../../assets/images/projectimgs/002_proj.jpg';
import proj003 from '../../assets/images/projectimgs/003_proj.jpg';
import proj004 from '../../assets/images/projectimgs/004_proj.jpg';
import proj005 from '../../assets/images/projectimgs/005_proj.jpg';
import proj006 from '../../assets/images/projectimgs/006_proj.jpg';
import proj007 from '../../assets/images/projectimgs/007_proj.jpg';

import ProjectCard from '../../components/ProjectCard';
import { v4 as uuidv4 } from "uuid";
import Carroussel from '../../components/Carroussel';
import { useState } from 'react';

const Projects = () => {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj001} title="SOUTH AMERICA MEMES - BRAHMOU"/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj002} title="LARE DIAS - #naturaléiraobanheiro METAMUCIL" />
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj003} title="RAFAEL PIMENTA COMO PROFESSOR JAMBINHO - JAMBO PET"/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj004} title="RAFA PIMENTA - FILTRO ANO NOVO NUBANK"/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj005} title="LARE DIAS - CONVIDADA PARA 5° TEMPORADA DO #ReelersCH COM A CAPRICHO"/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj006} title="TEKA MAFRA - Mês do Orgulho LGBTQIAPN+"/>
      )
    },
    {
      key: uuidv4(),
      content: (
        <ProjectCard imagem={proj007} title="Bad Vibes - Larica Club Social"/>
      )
    },
  ];

  const isMobile = window.innerWidth < 768;

  const [goToSlide, setGoToSlide] = useState(null);

  const goPrevious = () => {
    setGoToSlide(goToSlide - 1);
  };

  const goNext = () => {
    setGoToSlide(goToSlide + 1);
  };

  return (
    <section className="projects-container">
      <div id="trabalhos"></div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="container">
            <h2 className="proj-heading" anim-viewport="right">
              TRABALHOS
            </h2>
              <div className="row justify-content-center carouselzin">
                <Carroussel
                  cards={cards}
                  height={isMobile ? 300 : 570}
                  width={isMobile ? "90%" : "70%"}
                  offset={1}
                  showArrows={true}
                  goToSlide={goToSlide}
                  setGoToSlide={setGoToSlide}
                />
                <div className="proj-navigator">
                  <button type="button" onClick={goPrevious}>
                  <svg className="arrow  " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg>
                  </button>
                  <button type="button" onClick={goNext}>
                    <svg className="arrow  " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
)
};

export default Projects;