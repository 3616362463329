import './styles.css';
import Insta from '../../assets/images/WID_INSTA.png';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
  const [state, handleSubmit] = useForm("xdordgnr");

  return (
    <section className="contact-container">
      <div id="contato"></div>
       <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="container">
          <h2 className="contact-heading">
            CONTATO
          </h2>
            <div className="row">
              <div className="col-lg-6">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="nome" className='mt-4 ml-2'>
                      <span className="title">NOME: </span>
                    </label>
                    <input type="text" name="nome" id="nome" placeholder='nome completo' className="form-control" required/>
                    <ValidationError 
                      prefix="Nome" 
                      field="nome"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className='ml-2 mt-1'>
                      <span className="title">E-MAIL: </span>
                    </label>
                    <input type="email" name="email" id="email" placeholder='seu melhor e-mail' className="form-control" required/>
                    <ValidationError 
                      prefix="E-mail" 
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="nome" className='ml-2 mt-1'>
                      <span className="title">ASSUNTO:</span>
                    </label>
                    <select name="subject" id="subject" className='form-control'>
                      <option value="quero ser cast" selected>
                        Quero ser cast!
                      </option>
                      <option value="quero fechar um job">
                        Quero fechar um job! 
                      </option>
                      <option value="quero ser da equipe flocks">
                        Quero ser da equipe Flocks!
                      </option>
                      <option value="outro">
                        Outro
                      </option>
                    </select>
                    <ValidationError 
                      prefix="Assunto" 
                      field="subject"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message" className='ml-2 mt-1'>
                      <span className="title">MENSAGEM: </span>
                    </label>
                    <textarea type="message" name="message" id="message" className="form-control" required/>
                    <ValidationError 
                      prefix="Mensagem" 
                      field="message"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <button disabled={state.submitting} type="submit" className="btn btn-dark saibamais enviar btn-lg">Enviar</button>
                  </div>
                </form>
                {
                  state.succeeded && (
                    <div className="alert alert-success">
                      Obrigado! Sua mensagem foi enviada com sucesso.
                    </div>
                  )
                }
              </div>
              <div className="col-lg-6 text-center">
                <img className='img-fluid insta-img mb-4' src={Insta} alt="Screenshot do Instagram da Flocks" />
                <p className='social-heading'>
                  acompanhe a gente nas redes sociais:
                </p>
                <span className="social-icons mt-4">
                  <span className="fa-stack">
                      <a href="https://www.linkedin.com/company/flocks/">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fab fa-linkedin fa-stack-1x"></i>
                      </a>
                  </span>
                  <span className="fa-stack">
                      <a href="https://www.instagram.com/flocks.tv/">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fab fa-instagram fa-stack-1x"></i>
                      </a>
                  </span>
                  <span className="fa-stack">
                      <a href="https://twitter.com/flocks_tv">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fab fa-twitter fa-stack-1x"></i>
                      </a>
                  </span>
                  <span className="fa-stack">
                      <a href="https://www.facebook.com/FlocksTv/">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                  </span>
              </span>
              </div>
            </div>
          </div>
        </div>
       </div>
    </section>
);
};

export default Contact;