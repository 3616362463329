import './styles.css';
import { FIRST, SORTED } from '../../data/casting';

const allCasting = [...FIRST, ...SORTED.sort(() => Math.random() - 0.5)];


const Casting = ({setData, setModalOpened}) => (
    <section className="casting-container" >
      <div id="criadores">
        
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="container">

            <h2 className='cast-heading' anim-viewport="right">CRIADORES</h2>
            <div className="row justify-content-center">
              {
                allCasting.map((item, index) => {
                  return (
                    <div className="col-lg-2 col-md-4 col-6 mb-4 mt-4 cast" key={index} onClick={() => {
                      setData({...item, color: "#00000"})
                      setModalOpened(true)
                    }}>
                      <div className="casting-image-wrap">
                        <img src={item.image} alt={item.title} className="img-fluid casting-image" />
                      </div>
                      <div className="cast-title">
                        {item.title}
                      </div>
                    </div>
                  )
                })
              }
              </div>
          </div>
        </div>
      </div>
    </section>
);

export default Casting;