/* CONTEÚDOS MEMÉTICOS */

import MemeticosSam from '../assets/images/casting/memeticos/SAM.png';
import MemeticosLixeira from '../assets/images/casting/memeticos/lixeira.jpg';
import MemeticosMacintosh from '../assets/images/casting/memeticos/macintosh.jpg';
import MemeticosMemesFm from '../assets/images/casting/memeticos/memes_fm.jpg';
import MemeticosGalo from '../assets/images/casting/memeticos/galo.jpg';
import MemeticosBadvibes from '../assets/images/casting/memeticos/badvibes.jpg';


import HumorClepton from '../assets/images/casting/humor/CLEPTON.png';
import HumorDoug from '../assets/images/casting/humor/doug.jpg';
import HumorBuslife from '../assets/images/casting/humor/BUSLIFE.png';
import HumorKitarino from '../assets/images/casting/humor/kitarino.jpg';
import HumorTeka from '../assets/images/casting/humor/teka.jpg';
import HumorDani from '../assets/images/casting/humor/dani.jpg';
import HumorLuan from '../assets/images/casting/humor/luan.jpg';
import HumorErison from '../assets/images/casting/humor/erison.jpeg';
import HumorLaredias from '../assets/images/casting/humor/laredias.jpg';
import HumorInvestidor from '../assets/images/casting/humor/investidor.jpg';
import HumorPiunti from '../assets/images/casting/humor/piunti.jpg';
import HumorPimenta from '../assets/images/casting/humor/pimenta.jpg';
import HumorCinthia from '../assets/images/casting/humor/cinthia.jpeg';
import HumorCenourinha from '../assets/images/casting/humor/cenourinha.jpg';
import HumorLuiz from '../assets/images/casting/humor/luiz.jpeg';

/* CULTURA E CONHECIMENTO */
import CulturaBuenas from '../assets/images/casting/cultura/EDUARDO.png';

export const FIRST = [
  {
    image: MemeticosSam,
    title: 'South America Memes',
    text: "A página referência quando se trata de memes.",
    networks: [
      ['instagram', 'https://www.instagram.com/southamericamemes/'],
      ['youtube', 'https://www.youtube.com/c/SouthAmericaMemes'],
      ['twitter', 'https://twitter.com/SoutAmericMemes'],
      ['tiktok', 'https://www.tiktok.com/@southamericamemes'],
    ]
  },
  {
    image: HumorClepton,
    title: 'Clepton',
    text: "Clepton, porque a vida é um improviso;",
    networks: [
      ['instagram', 'https://www.instagram.com/oerickclepton/'],
      ['youtube', 'https://www.youtube.com/channel/UCDaM5npyBufSF9XF7XnDlsA'],
      ['tiktok', 'https://www.tiktok.com/@clepton?lang=pt-BR'],
    ]
  },
  {
    image: HumorCenourinha,
    title: 'Cenourinha',
    text: 'A cenourinha do Facebook agora em todas as redes! 🥕🥕🥕',
    networks: [
      ['instagram', 'https://www.instagram.com/cenourinha_oficial/?hl=en'],
      ['tiktok', 'https://www.tiktok.com/@cenourinha8'],
      ['youtube', 'https://www.youtube.com/@Cenourinha_Oficial'],
      ['facebook', 'https://www.facebook.com/cenourinhaoficial']
    ]
  },
]

export const SORTED = [
  {
    image: HumorDoug,
    title: 'Doug Oliver',
    text: "Diretamente da Mansão das Pocs, para alegrar o seu dia.",
    networks: [
      ['instagram', 'https://www.instagram.com/dougoliverx/'],
      ['youtube', 'https://www.youtube.com/channel/UC5w5_7I1lM1UkdyDSvuSYFQ'],
      ['twitter', 'https://twitter.com/dougoliverx'],
    ]
  },
  {
    image: HumorBuslife,
    title: 'BuslifeBR',
    text: "Construindo a vida sob 4 rodas.",
    networks: [
      ['instagram', 'https://www.instagram.com/buslifebr/'],
      ['youtube', 'https://www.youtube.com/c/BuslifeBR'],
      ['tiktok', 'https://www.tiktok.com/@buslifebr?lang=pt-BR'],
    ]
  },
  {
    image: HumorKitarino,
    title: 'Kitarino',
    text: "O nerd com as melhores cantadas da Internet",
    networks: [
      ['tiktok', 'https://www.tiktok.com/@kitarinooo'],
      ['instagram', 'https://www.instagram.com/kitarinoo/'],
      ['twitch', 'https://twitch.tv/kitarinoooo/'],
    ]
  },
  {
    image: HumorTeka,
    title: 'Teka Mafra',
    text: "O combo 3 em 1: humor, dublagem e atuação em um só canal!",
    networks: [
      ['instagram', 'https://www.instagram.com/tekamafra/'],
      ['youtube', 'https://www.youtube.com/@TekaMafra'],
      ['tiktok', 'https://www.tiktok.com/@tekamafra'],
    ]
  },
  {
    image: HumorDani,
    title: 'Dani Kripka',
    text: "O humor do cotidiano contado por um ator, modelo e futuro dublador.",
    networks: [
      ['instagram', 'https://www.instagram.com/danikripka/'],
      ['youtube', 'https://www.youtube.com/@danikripka'],
      ['tiktok', 'https://www.tiktok.com/@danikripka'],
    ]
  },
  {
    image: HumorLuan,
    title: 'Luan Fernandes',
    text: "Humor, cultura pop e games, tudo em um só lugar!",
    networks: [
      ['instagram', 'https://www.instagram.com/lgfluan/'],
      ['youtube', 'https://youtube.com/c/LuanFernandess'],
      ['twitter', 'https://twitter.com/lgfluan'],
      ['tiktok', 'https://www.tiktok.com/@luanfernandes'],
    ]
  },
  {
    image: HumorErison,
    title: 'Erison Silva',
    text: "Compartilhando seu estilo de vida autêntico com uma boa dose de humor e muito amor em família, sempre acompanhado de um bom café.",
    networks: [
      ['instagram', 'https://www.instagram.com/erisonsilva_'],
      ['tiktok', 'https://www.tiktok.com/erisonsilvaa'],
    ]
  },
  {
    image: HumorLuiz,
    title: 'Luiz Teixeira',
    text: "Cozinhando com amor, diversão e muita personalidade!",
    networks: [
      ['instagram', 'https://www.instagram.com/luiz.txeira/ '],
      ['tiktok', 'https://www.tiktok.com/@luiz.txeira'],
    ]
  },
  {
    image: HumorLaredias,
    title: 'Lare Dias',
    text: "Opiniões sinceras entre uma gracinha e outra!",
    networks: [
      ['instagram', 'https://www.instagram.com/llarieuu/'],
      ['tiktok', 'https://www.tiktok.com/@larieuu'],
      ['twitter', 'https://twitter.com/llarisoueu'],
    ]
  },
  {
    image: HumorInvestidor,
    title: 'Investidor da depressão',
    text: "Investindo no seu bom humor",
    networks: [
      ['instagram', 'https://www.instagram.com/investidordadepressao/'],
      ['tiktok', 'https://www.tiktok.com/@investidordadepressao?_t=8UXJu9JKR6r&_r=1'],
      ['youtube', 'https://m.youtube.com/c/InvestidorDaDepress%C3%A3o'],
    ]
  },
  {
    image: HumorPiunti,
    title: 'Piunti',
    text: "Os bastidores do mundo sertanejo",
    networks: [
      ['instagram', 'https://www.instagram.com/piunti/'],
      ['youtube', 'https://www.youtube.com/c/Andr%C3%A9Piunti'],
      ['twitter', 'https://twitter.com/piunti'],
    ]
  },
  {
    image: HumorPimenta,
    title: 'Rafael Pimenta',
    text: "Um olhar torto sobre a vida",
    networks: [
      ['instagram', 'https://instagram.com/orafapimenta'],
      ['youtube', 'https://www.youtube.com/rafaelpimentavideos'],
      ['tiktok', 'https://tiktok.com/@orafaelpimenta'],
    ]
  },
  {
    image: HumorCinthia,
    title: 'Cinthia',
    text: 'Makes originais? Nostalgia da adolescência? Stories aleatórios? Cinthia tem tudo isso e muito mais para alegrar seu dia.',
    networks: [
      ['instagram', 'https://www.instagram.com/cinthiarba/'],
      ['twitter', 'https://twitter.com/cinthiarba'],
      ['tiktok', 'https://www.tiktok.com/@cinthia'],
      ['youtube', 'https://www.youtube.com/@CinthiaRodrigues'],
    ]
  },
  {
    image: MemeticosLixeira,
    title: 'Lixeira Memes',
    text: "Os piores memes da internet que te farão morrer de rir.",
    networks: [
      ['instagram', 'https://www.instagram.com/lixeiramemes/'],
    ]
  },
  {
    image: MemeticosMacintosh,
    title: 'Macintosh 1984',
    text: "O último romântico da internet",
    networks: [
      ['instagram', 'https://www.instagram.com/macintosh1984/'],
      ['twitter', 'https://twitter.com/_macintosh1984'],
      ['tiktok', 'https://www.tiktok.com/@macintosh1984'],
    ]
  },
  {
    image: MemeticosMemesFm,
    title: 'Filosofia Moderna',
    text: "Os memes mais filosóficos da Filosofia Moderna e Geral!",
    networks: [
      ['instagram', 'https://www.instagram.com/filomoderna/?hl=pt-br'],
      ['twitter', 'https://twitter.com/FilosofiaMderna'],
    ]
  },
  {
    image: MemeticosGalo,
    title: 'Galo Caminhoneiro',
    text: "Memes para você cacarejar de rir!",
    networks: [
      ['instagram', 'https://www.instagram.com/galocaminhoneiro/'],
      ['twitter', 'https://twitter.com/GALODOCAMINHAO'],
    ]
  },
  {
    image: MemeticosBadvibes,
    title: 'Bad Vibes Memes',
    text: "Memes duvidosos para o seu dia-a-dia.",
    networks: [
      ['instagram', 'https://www.instagram.com/badvibesmemes/'],
      ['twitter', 'https://twitter.com/badvibesmemes'],
      ['tiktok', 'https://www.tiktok.com/@badvibesmemes'],
      ['twitch', 'https://www.twitch.tv/badvibesmemes']
    ]
  },
  {
    image: CulturaBuenas,
    title: 'Buenas Ideias',
    text: "Histórias que não vão cair no ENEM.",
    networks: [
      ['instagram', 'https://www.instagram.com/buenasideias/'],
      ['youtube', 'https://www.youtube.com/channel/UCQRPDZMSwXFEDS67uc7kIdg'],
      ['twitter', 'https://twitter.com/BuenasIdeias'],
    ]
  },
]