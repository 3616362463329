import './styles.css';
import MMA2019 from '../../assets/images/mma/2019.png';
import MMA2020 from '../../assets/images/mma/2020_2.jpg';
import MMA2021 from '../../assets/images/mma/2021.png';
import MMA2022 from '../../assets/images/mma/2022.png';
import MMA2023 from '../../assets/images/mma/2023.png';
import { v4 as uuidv4 } from "uuid";

export default function MemeAwards() {

  const years = [
    {
      key: uuidv4(),
      year: 2019,
      image: MMA2019
    },
    {
      key: uuidv4(),
      year: 2020,
      image: MMA2020
    },
    {
      key: uuidv4(),
      year: 2021,
      image: MMA2021
    },
    {
      key: uuidv4(),
      year: 2022,
      image: MMA2022
    },
    {
      key: uuidv4(),
      year: 2023,
      image: MMA2023
    }
  ]
  return (
    <section className="mma-container" id='memeawards'>
      <div className="tapeup">
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="container">
            <h2 className="mma-heading" anim-viewport="right" >
              MEMEAWARDS
            </h2>
            <div className="row justify-content-center">
              <div className="col-12 text-center mb-4">
                  <p className="mma-desc">
                   Somos pioneiros em campanhas com memes no Brasil e criadores da única premiação de memes no mundo.
                  </p>
              </div>
              <div className="col-12">
                <div className="row justify-content-center">
                  {
                    years.map((year) => (
                      <div className="col-lg-2 col-md-5 col-8" key={year.key}>
                        <div className="mma-card text-center">
                          <img src={year.image} alt="2019" className='img-fluid rounded-circle'/>
                          <span>
                            {year.year}
                          </span>
                        </div>
                      </div>
                    ))
                  }
                  <div className="timeline-line"></div>
                </div>
              </div>
              <div className="col-12 text-center">
                <a href="https://memeawards.com.br" target="_blank" rel='noreferrer' className="btn btn-light saibamais btn-lg">SAIBA MAIS</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tape">
      </div>
    </section>
  )
}