import './styles.css';
import Autor from '../../assets/icons/autor.svg';
import Clapper from '../../assets/icons/clapper.svg';
import DataScience from '../../assets/icons/data-science.svg';
import Lampada from '../../assets/icons/lampada.svg';
import MarketingDigital from '../../assets/icons/marketing-digital.svg';
import Megafone from '../../assets/icons/megafone.svg';
import PromocaoDeCarreira from '../../assets/icons/promocao-de-carreira.svg';
import Youtube from '../../assets/icons/youtube.svg';

const WHAT_WE_DO = [
    {
        image: MarketingDigital,
        title: 'branded content',
        text: 'Encontramos a melhor solução e o melhor hub de influenciadores para o objetivo de cada campanha.'
    },
    {
        image: Lampada,
        title: 'projetos especiais',
        text: 'Criamos e executamos projetos 100% originais de ponta a ponta.'
    },
    {
        image: Autor,
        title: 'storytellings originais',
        text: 'Escolhemos a história certa para traduzir o objetivo de marcas e produtos.'
    },
    {
        image: Megafone,
        title: 'redes sociais',
        text: 'Planejamos estratégias personalizadas para criadores e marcas crescerem em audiência e engajamento.'
    },
    {
        image: DataScience,
        title: 'inteligência digital',
        text: 'Analisamos dados e sentimentos, da criação ao fim da entregas, garantindo os inputs necessários para as melhores decisões.'
    },
    {
        image: Clapper,
        title: 'Produção audiovisual',
        text: 'Nos responsabilizamos pelo roteiro, captação, pós produção e identidade visual de marcas e produtos.'
    },
    {
        image: PromocaoDeCarreira,
        title: 'Gestão de carreiras',
        text: 'Agenciamos influenciadores, auxiliando estrategicamente e comercialmente nas suas carreiras.'
    },
    {
       image: Youtube,
       title: 'Criação de canais digitais',
       text: 'Desenvolvemos e gerimos redes sociais e canais no youtube, desde a criação de conteúdo até a análise de resultados.'
    }
]

const WhatWeDo = () => (
    <section className="whatwedo-container">
      <div id="solucoes"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <h2 className="wwd-heading mb-4" anim-viewport="right">
                SOLUÇÕES
              </h2>
              <br />
              <div className="row mt-4">
                {
                  WHAT_WE_DO.map((item, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-12 mb-4 text-center solution">
                      <img src={item.image} alt={item.title} className="img-fluid" />
                      <h3>
                        {
                          item.title
                        }
                      </h3>
                      <p>
                        {
                          item.text
                        }
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);

export default WhatWeDo;