import { useEffect, useState } from 'react';
import './App.css';
import Casting from './sections/casting';
import Contact from './sections/contact';
import Navbar from './sections/navbar';
import Partners from './sections/partners';
import Projects from './sections/projects';
import Splash from './sections/splash';
import WhatWeDo from './sections/whatwedo';
import WhoWeAre from './sections/whoweare';
import CastModal from './components/CastModal';
import Data from './sections/data';
import MemeAwards from './sections/mma';
import { FIRST } from './data/casting';

function App() {
  const [modalData, setModalData] = useState(FIRST[0]);

  useEffect(() => {
    // Função para adicionar classes de animação aos elementos com o atributo anim-viewport
    const handleAnimation = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          const animationDirection = element.getAttribute('anim-viewport');

          if (animationDirection) {
            element.classList.add(`animate-${animationDirection}`);
          }
        }
      });
    };

    // Configuração do IntersectionObserver
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Define a porcentagem visível para acionar a animação
    };

    // Cria o IntersectionObserver e observa os elementos com o atributo anim-viewport
    const observer = new IntersectionObserver(handleAnimation, options);
    const elementsToObserve = document.querySelectorAll('[anim-viewport]');
    elementsToObserve.forEach((element) => observer.observe(element));

    return () => {
      // Limpar o IntersectionObserver quando o componente for desmontado
      observer.disconnect();
    };
  }, []);

  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div style={{position: 'relative'}}>
      <CastModal data={modalData} setData={setModalData} modalOpened={modalOpened} setModalOpened={setModalOpened}/>
      <Navbar/>
      <Splash/>
      <WhoWeAre/>
      <Data/>
      <MemeAwards/>
      <WhatWeDo/>
      <Casting setData={setModalData} setModalOpened={setModalOpened}/>
      <Projects/>
      <Partners/>
      <Contact/>
    </div>
  );
}

export default App;
